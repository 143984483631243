@use "variables" as *;

@font-face {
    font-family: 'DIN';
    src: url('/site-assets/fonts/DIN-Bold.woff2') format('woff2'),
        url('/site-assets/fonts/DIN-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN';
    src: url('/site-assets/fonts/DIN-Light.woff2') format('woff2'),
        url('/site-assets/fonts/DIN-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN';
    src: url('/site-assets/fonts/DIN-Medium.woff2') format('woff2'),
        url('/site-assets/fonts/DIN-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN';
    src: url('/site-assets/fonts/DIN-Regular.woff2') format('woff2'),
        url('/site-assets/fonts/DIN-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

// Typography
html {
    font-size: 100%;
}

body {
    font-family: $font-family;
    font-weight: 400;
    line-height: 1.75;
    color: $font-color;
}

p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.7;
    margin-bottom: 1rem;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;
}

// h1,
// .h1 {
//     margin-top: 0;
//     font-size: 4.209rem;
// }

// h2,
// .h2 {
//     font-size: 3.157rem;
// }

// h3,
// .h3 {
//     font-size: 2.369rem;
// }

// h4,
// .h4 {
//     font-size: 1.777rem;
// }

// h5,
// .h5 {
//     font-size: 1.333rem;
// }

small,
.text_small {
    font-size: 0.8rem;
}

.text-white {

    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    * {
        color: $base-white;
    }
}