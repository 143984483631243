@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        background: $primary-color;
        color: $base-white;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        z-index: 9;
        @include trans;

        //NEW Navigation style

        .featured-insights-image-wrapper {
            .post-tag {
                position: absolute;
                z-index: 1;
                top: 10px;
                left: 10px;

                span {
                    border-radius: 8px;
                    background: #99CCCC;
                    font-size: 12px;
                    font-weight: 600;
                    padding: 5px 8px;
                    color: $font-color;
                }
            }
        }

        .child-nav {
            a {
                &:before {
                    @include trans;
                }

                &:hover,
                &:focus,
                &:active {
                    &:before {
                        transform: rotate(-45deg);
                    }
                }
            }
        }


        .featured-image {
            border-radius: 20px;
            max-height: 200px;
            object-fit: cover;
        }

        .post-subtitle {
            font-size: 14px;
            color: #777777;
        }

        .post-contact {
            p {
                margin: 0;
            }

            a {
                font-size: 14px;
                color: $primary-color;

                &:hover,
                &:focus,
                &:active {
                    color: $primary-color !important;
                    text-decoration: underline;
                }
            }
        }

        .post-description {
            p {
                margin: 0;
                font-size: 14px;
                color: #C4C4C4;
                @include line-clamp(14px, 1.2, 2);
            }

            &.post-blog {
                font-size: 14px;
                color: #C4C4C4;
                @include line-clamp(14px, 1.2, 2);
            }
        }

        .post-title {
            font-weight: 500;
        }

        .sub-children-title {
            border-bottom: 1px solid #3B3831;
            padding-bottom: 1.407rem;
            margin-bottom: 1rem;
            margin-top: 1rem;
            font-size: 14px;
        }

        .primaryBtn-wrapper {

            p {
                margin: 0;
            }

            a {
                @include primaryBtn;
                font-size: 14px;
                width: 100%;
                justify-content: center;
                margin: 0;

                &:hover,
                &:focus,
                &:active {
                    text-decoration: none !important;
                }

                &:after {
                    content: none;
                }
            }
        }



        .dropdownItem {
            width: 90%;
            max-width: 1440px;
            margin: 0 auto;
            background: $base-black;
            position: absolute;
            left: 0;
            right: 0;
            border-radius: 0 0 25px 25px;
            display: none;
            overflow: hidden;
            // @mixin box-shadow($top, $left, $blur, $size, $color) {
            @include box-shadow(1px, 1px, 5px, 1px, rgba(0, 0, 0, 0.1));

            &:not([data-parent-id="Specialismss"]):not([data-parent-id="Employers"]):not([data-parent-id="Markets"]) {
                .sub-item-parent {
                    display: flex;
                    gap: 10px;
                    margin-bottom: 0.5rem;

                    &:before {
                        content: "";
                        width: 26px;
                        min-width: 26px;
                        height: 26px;
                        display: block;
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-image: url('data:image/svg+xml,<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="26" height="26" rx="13" fill="%23FFCC33"/><path d="M17.0721 13.4854C17.3093 13.2169 17.3093 12.7809 17.0721 12.5125L14.0362 9.07634C13.7991 8.80789 13.4139 8.80789 13.1767 9.07634C12.9395 9.34479 12.9395 9.78075 13.1767 10.0492L15.1785 12.3128H9.35718C9.02133 12.3128 8.75 12.6199 8.75 13C8.75 13.3801 9.02133 13.6872 9.35718 13.6872H15.1766L13.1786 15.9508C12.9414 16.2192 12.9414 16.6552 13.1786 16.9237C13.4158 17.1921 13.801 17.1921 14.0381 16.9237L17.074 13.4875L17.0721 13.4854Z" fill="black"/></svg>');

                    }
                }
            }



            &:not([data-mobile-parent-id="Specialismss"]):not([data-mobile-parent-id="Employers"]):not([data-mobile-parent-id="Markets"]) {
                .sub-item-parent {
                    margin: 0;
                    border: none;

                    @media (min-width: $breakpoint-lg) {
                        margin: 0 0 1rem 0;
                    }
                }

                .child-nav {
                    border: none;
                    margin-top: 0;

                    @media (min-width: $breakpoint-lg) {
                        &:not(:nth-last-child(-n+2)) {
                            border-bottom: 1px solid #C4C4C4;
                        }
                    }


                }

            }

            &[data-parent-id="Specialisms"] {
                .child-nav {
                    border: none !important
                }

                .right-column {
                    .wrapper {
                        grid-row-gap: 0;
                    }
                }
            }


            &[data-mobile-parent-id="Specialismss"],
            &[data-mobile-parent-id="Employers"],
            &[data-mobile-parent-id="Markets"] {
                .sub-item-parent {
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 12px;
                }

                .sub-item-child {
                    margin-bottom: 0.5rem;
                }

            }

            &[data-parent-id="Specialismss"],
            &[data-parent-id="Employers"],
            &[data-parent-id="Markets"] {
                .child-nav {
                    .sub-item-parent {
                        font-weight: 600;
                        font-size: 14px;
                        color: #777777;
                        margin-bottom: 0.5rem;


                    }
                }

                .sub-item {
                    display: flex;
                    gap: 10px;
                    margin-bottom: 0.5rem;

                    @media (min-width: $breakpoint-lg) {
                        margin-bottom: 1rem;
                    }

                    &:before {
                        content: "";
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-image: url('data:image/svg+xml,<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="26" height="26" rx="13" fill="%23FFCC33"/><path d="M17.0721 13.4854C17.3093 13.2169 17.3093 12.7809 17.0721 12.5125L14.0362 9.07634C13.7991 8.80789 13.4139 8.80789 13.1767 9.07634C12.9395 9.34479 12.9395 9.78075 13.1767 10.0492L15.1785 12.3128H9.35718C9.02133 12.3128 8.75 12.6199 8.75 13C8.75 13.3801 9.02133 13.6872 9.35718 13.6872H15.1766L13.1786 15.9508C12.9414 16.2192 12.9414 16.6552 13.1786 16.9237C13.4158 17.1921 13.801 17.1921 14.0381 16.9237L17.074 13.4875L17.0721 13.4854Z" fill="black"/></svg>');
                        width: 26px;
                        min-width: 26px;
                        height: 26px;
                        display: block;
                    }
                }


            }

            a {

                &:hover,
                &:focus,
                &:active {
                    color: $font-color;
                    text-decoration: underline;
                }

                &.primaryBtn {

                    &:hover,
                    &:focus,
                    &:active {
                        text-decoration: none;
                    }
                }
            }

            &.active {
                display: block;
            }

            @media (max-width: $breakpoint-lg) {

                .child-nav,
                .sub-children-title {
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                }
            }
        }

        .left-column {
            border: solid $primary-color;
            border-width: 0 0 1px 1px;
            border-radius: 0 0 0 25px;
        }

        .right-column {
            background: $base-white;
            color: $font-color;

            .wrapper {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 60px;
                row-gap: 20px;
            }
        }

        .nav-item {
            &.active {
                .dropdown {
                    border-color: $base-black;
                    background-color: #e5b43e;
                }
            }
        }

        .mobileWrapper {

            .nav {
                .nav-item {
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                }
            }

            a {
                font-size: 14px;
                display: block;
                width: 100%;
                padding: 0.735rem 0;
            }

            .nav-item {
                // margin-bottom: 0.7rem;
                border: none;

                // &:hover,
                // &:focus,
                // &:active {
                //     border: none !important;

                // }

                button {
                    font-weight: 300;
                    font-size: 14px;
                    padding: 0.735rem 0;
                    color: $base-white;


                    &.dropdown {
                        &.has-children {
                            &:after {
                                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none"><path d="M11.6653 7.20009C12.1116 7.64263 12.1116 8.3613 11.6653 8.80384L5.95224 14.4683C5.50591 14.9108 4.78108 14.9108 4.33475 14.4683C3.88842 14.0258 3.88842 13.3071 4.33475 12.8645L9.24079 8.0002L4.33832 3.13585C3.89199 2.69331 3.89199 1.97463 4.33832 1.5321C4.78465 1.08956 5.50948 1.08956 5.95581 1.5321L11.6688 7.19655L11.6653 7.20009Z" fill="white"/></svg>');
                                transform: none;

                            }
                        }

                        &:hover,
                        &:focus,
                        &:active {
                            border: none;
                            color: $base-white;
                        }
                    }
                }
            }
        }

        // END

        .reciteWrapper {
            .recite-btn {
                border: none;
                background: transparent;

                &:hover,
                &:focus,
                &:active {
                    text-decoration: underline;
                }
            }
        }

        .mainWrapper {
            padding: 1.25rem 0;
        }

        .lang_select {
            span {
                color: $base-white;
            }

            .btn-secondary {
                background: transparent;
                border: none;
                font-weight: 500;
            }

            .dropdown-menu {
                border: none;
                min-width: auto;
                width: auto;
                box-shadow: 1px 1px 15px rgba($base-black, 0.2);
                text-align: center;

                .dropdown-item {
                    font-weight: 500;
                    display: inline-block;

                    &.active {
                        display: none;
                    }
                }

                &:before {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: -10px;
                    width: 0;
                    height: 0;
                    border-color: transparent transparent #fff;
                    border-style: solid;
                    border-width: 0 9px 10px;
                    transform: translateX(-50%);
                }
            }

            .dropdown-item {
                padding: 0;
                color: $base-black;
                min-width: 20px;
                min-height: 20px;
                margin: 0.2rem 0;
                @include trans;

                &.active {
                    background: transparent;
                }

                &:hover,
                &:focus,
                &:active {
                    opacity: 0.5;
                }
            }

            span {
                display: none;

                &.active {
                    display: block;
                }
            }

            svg {
                border: 1px solid $base-black;
                width: 36px;
                height: 100%;
                @include border-radius(100%);
            }
        }

        .nav-item {
            a {
                @include trans;

                &:hover,
                &:focus,
                &:active {
                    color: $primary-color;
                }
            }
        }

        .mobile-menu {
            // width: 100px;
            // height: 40px;
            padding: 0.7rem 1rem;
            background: $bg-light;
            color: $font-color;
            border: none;
            @include trans;
            @include border-radius(10px);


            &.active {
                color: $base-white;
                background: #B32121;

                &:before {
                    top: 20px;
                    @include transform(rotate(45deg));
                }

                &:after {
                    top: 20px;
                    @include transform(rotate(-45deg));
                }

                &:hover,
                &:focus,
                &:active {
                    background: darken(#B32121, 10);


                }
            }

            // &:before {
            //     top: 16px;
            //     left: 8px;
            // }

            // &:after {
            //     top: 23px;
            //     left: 8px;
            // }

            // &:before,
            // &::after {
            //     content: "";
            //     position: absolute;
            //     width: 25px;
            //     height: 2px;
            //     background: $bg-dark;
            // }

            &:hover,
            &:focus,
            &:active {
                background: $primary-color;

                &:before,
                &::after {
                    background: $base-white;
                }
            }
        }

        .logo {
            cursor: pointer;
            @include trans;

            &:hover,
            &:focus,
            &:active {
                opacity: 0.5;
            }
        }

        .sub-children-wrapper {

            .dropdownItem {
                position: relative;
                border-radius: 0;
                width: 100%;

                a {

                    &:hover,
                    &:focus,
                    &:active {
                        color: $primary-color;
                        text-decoration: none;
                    }
                }
            }

            .child-nav {
                margin-top: 0.5rem;

                &:not(:first-child) {
                    margin-top: 1rem;
                    // padding-top: 1rem;
                    border-top: 1px solid #3B3831;
                }
            }



            .sub-item-parent {
                font-size: 14px;

                &.no-link {
                    margin-top: 1.5rem;
                }

                &:before {
                    content: none !important;
                }

                &:hover,
                &:focus,
                &:active {
                    color: $base-white;
                }
            }
        }

        .return-btn-wrapper {
            // padding-bottom: 0.7rem;
            border-bottom: 1px solid #3B3831 !important;
            padding-left: 1.5rem;
            padding-right: 1.5rem;


            .dropdown {
                color: $primary-color;
                display: flex;
                align-items: center;
                gap: 10px;

                &:before {
                    content: "";
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    background-image: url('data:image/svg+xml,<svg width="17" height="8" viewBox="0 0 17 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.646446 3.64645C0.451183 3.84171 0.451183 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646446 3.64645ZM17 3.5L1 3.5V4.5L17 4.5V3.5Z" fill="%23FFCC33"/></svg>');
                    width: 20px;
                    height: 20px;
                    display: block;
                    min-width: 16px;
                }
            }

            button {

                &:hover,
                &:focus,
                &:active {
                    text-decoration: underline;
                }
            }
        }

        button {
            font-size: 15px;

            &.dropdown {
                font-size: 17px;
                font-weight: 600;
                color: $base-black;
                border: solid transparent;
                border-width: 0 0 1px 0;
                border-radius: 10px 10px 0 0;
                padding: 0.6rem 1rem 0.4rem;
                background-color: transparent;
                @include trans;

                &.has-children {
                    padding-right: 1.75rem;

                    &:after {
                        content: "";
                        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none"><path d="M11.6653 7.20009C12.1116 7.64263 12.1116 8.3613 11.6653 8.80384L5.95224 14.4683C5.50591 14.9108 4.78108 14.9108 4.33475 14.4683C3.88842 14.0258 3.88842 13.3071 4.33475 12.8645L9.24079 8.0002L4.33832 3.13585C3.89199 2.69331 3.89199 1.97463 4.33832 1.5321C4.78465 1.08956 5.50948 1.08956 5.95581 1.5321L11.6688 7.19655L11.6653 7.20009Z" fill="%23000000"/></svg>');
                        background-repeat: no-repeat;
                        width: 10px;
                        height: 11px;
                        position: absolute;
                        right: 9px;
                        top: calc(50% + 0.1rem);
                        transform: translateY(-50%) rotate(90deg);
                    }
                }

                &:hover,
                &:focus,
                &:active {
                    border-color: $base-black;
                    background-color: #e5b43e;
                }

            }
        }

        .dropdown-navigations {
            background: rgba($base-white, 0.9);
            left: 0;
            right: 0;
            color: $font-color;
            @include border-radius(25px);
            // @mixin box-shadow($top, $left, $blur, $size, $color) {
            @include box-shadow(1px, 1px, 5px, 1px, rgba(0, 0, 0, 0.1));

            a {
                @include trans;

                &:hover,
                &:focus,
                &:active {
                    color: $primary-color;
                }
            }
        }

        .search-icon {
            cursor: pointer;
            padding: 1rem;
            background: $base-black;
            border: none;
            @include border-radius(100%);
            @include trans;

            svg {
                width: 20px;
                height: 20px;

                path {
                    fill: $base-white;
                }
            }

            &:hover,
            &:focus,
            &:active {
                opacity: 0.5;
            }
        }

        .telephone-icon {
            svg {
                width: 18px;
                height: 18px;
            }
        }

        .mobile-search {
            .primaryBtn {
                padding: 0.7rem;
            }

            .icon {
                right: 15px;
                top: 7px;

                svg {
                    path {
                        fill: $secondary-color;
                    }
                }
            }

            .btn {
                width: 100%;
                background: $base-white;
                color: $font-color;
                text-align: left;

                &:after {
                    content: "";
                    position: absolute;
                    right: 18px;
                    top: 11px;
                    background-image: url("/site-assets/svg/black-magnifying-glass.svg");
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 18px;
                    height: 18px;
                }
            }

            svg {

                width: 20px;
                height: 20px;

                path {
                    fill: $base-white;
                }
            }

        }

        input {
            &#search {
                text-align: left;
                width: 100%;
                outline: none;
                padding: 0.5rem 4rem 0.5rem 2rem;
                background: $base-white;
                border: none;
                color: $font-color;
                @include border-radius(25px);


            }
        }

        .search-results {
            overflow-y: auto;

            ul {
                padding-left: 1rem;
            }

            a {
                @include trans;

                &:hover,
                &:focus,
                &:active {
                    color: $primary-color;
                }
            }
        }

        @media (min-width: $breakpoint-lg) {
            .search-results {
                height: 100vh;
                padding-bottom: 10rem;
            }
        }

        @media (max-width: $breakpoint-lg) {

            // .logo {
            //     svg {
            //         max-width: 90px;
            //     }
            // }
            .secondaryBtn {
                padding: 0.7rem 1rem
            }
        }
    }

    // &:global.main-navigation.active {
    //     border-bottom: 1px solid $base-white;
    // }

    &:global.mobile-dropdown {
        position: fixed;
        background: $base-black;
        z-index: 8;
        height: 100%;
        width: 100%;
        top: -200%;
        @include trans;
        padding-top: 10rem;
        color: $base-white;

        &.active {
            top: 0;
            overflow-y: scroll;

        }

        .accordion-item {
            border: none;
            background: transparent;

            .accordion-button {
                background: transparent;
                box-shadow: none;
                font-weight: 500;
                font-size: 1.2rem;
                padding-right: 3rem;
                color: $font-color;

                &:after {
                    content: "";
                    background-image: url("/site-assets/svg/black-arrow.svg");
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 15px;
                    height: 15px;
                    position: absolute;
                    right: 25px;
                    top: 17px;
                    @include transform(rotate(-90deg));
                }

                &.collapsed {
                    &:after {
                        @include transform(rotate(90deg));
                    }
                }

            }
        }

        .accordion-body {
            background: $primary-color;

            p {
                &:last-child {
                    margin: 0;
                }
            }

            a {
                font-weight: 500;
                padding: 0 !important;

                &:hover,
                &:focus,
                &:active {
                    color: $font-color;
                    opacity: 0.5;
                }
            }
        }

        .mobile-language {

            .btn-secondary {
                color: $font-color;
                padding: 0;
            }

            hr {
                border-color: $base-white;
            }

            .lang_select {
                .dropdown-menu {
                    max-width: 80px;

                    &:before {
                        content: none;
                    }
                }
            }
        }

        .mobileWrapper {
            max-height: 75vh;
        }
    }

    &:global.site-search {
        background: $dark-gray;
        padding: 1.2rem;

        .container {
            max-width: 1440px;
        }

        .primaryBtn {
            max-width: 50px;
        }

        .btn {
            text-align: left;
            width: 100%;
            outline: none;
            padding: 0.5rem 4rem 0.5rem 2rem;
            background: $base-white;
            border: none;
            color: $font-color;
            @include border-radius(25px);

            &:after {
                content: "";
                position: absolute;
                right: 18px;
                top: 11px;
                background-image: url("/site-assets/svg/black-magnifying-glass.svg");
                background-repeat: no-repeat;
                background-size: contain;
                width: 18px;
                height: 18px;
            }
        }

        .search {
            .search-icon {
                width: 20px;
                height: 20px;
                right: 20px;
                top: 5px;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        button {
            padding: 0.9rem;

            svg {
                min-width: 20px;
                min-height: 20px;

                path {
                    fill: $base-black;
                }
            }
        }

    }
}