@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        a {
            display: flex;
            width: 25px;
            height: 25px;
            background: $base-black;
            @include border-radius(100%);
            @include align-items(center);
            @include justify-content(center);
            @include trans;

            &:hover,
            &:focus,
            &:active {
                opacity: 0.5;
            }
        }

        svg {
            width: 15px;
            height: 15px;

            path {
                fill: $base-white;
                @include trans;
            }

        }
    }
}